import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MdOpenInNew } from "react-icons/md"

import Layout from "../components/Layout"
import Button from "../components/Button"
import Seo from "../components/Seo"

export const query = graphql`
  query getMovie($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        category,
        title
        year
        youtubeVideoId
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED,
              height: 250,
              width: 160,
            )
          }
          relativePath
        }
      }
    }
  }
`;

const MoviePageTemplate = ({ data }) => {
  const {
    frontmatter: {
      category,
      title,
      year,
      youtubeVideoId,
      description,
      image,
    }
  } = data.markdownRemark;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={`/images/${image.relativePath}`}
        isArticle={true}
      />
      <div className="grid grid-cols-6 py-6">
        <section className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <div className="flex flex-col md:flex-row">
            <div className="self-center md:self-start w-min">
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={`Cover ${title}`}
                className="rounded-xl"
              />
            </div>
            <div className="pl-0 pt-4 md:pl-4 md:pt-0">
              <span className="uppercase pb-2">{category}</span>
              <h1>{title}</h1>
              <h3>({year})</h3>
              <p className="py-4">{description}</p>
              <a
                href={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
                rel="noreferrer"
                target="_blank"
              >
                <div className="flex items-center">
                  <span className="pr-2">Guarda trailer su YouTube</span>
                  <MdOpenInNew />
                </div>
              </a>
            </div>
          </div>
          <div className="pt-12 text-center">
            <Button to="/piccoli-sollievi/cineteca" text="Torna alla cineteca" />
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default MoviePageTemplate
